import React from 'react';
import './css/Links.css';

import Logo from '../components/NavBar/images/LogoDMD.webp'
import IconTwitter from "../components/HomeSection/images/icons/Twitter.svg";
import IconYouTube from "../components/HomeSection/images/icons/YouTube.svg";
import IconFacebook from "../components/HomeSection/images/icons/Facebook.svg";
import IconInstagram from "../components/HomeSection/images/icons/Instagram.svg";
import IconLinkedIn from "../components/HomeSection/images/icons/LinkedIn.svg";
import IconTelegram from "../components/HomeSection/images/icons/Telegram.svg";
import IconVK from "../components/HomeSection/images/icons/VK.svg";
import IconTikTok from "../components/HomeSection/images/icons/TikTok.svg";
import IconDiscord from "../components/HomeSection/images/icons/Discord.svg";

const socialLinks = [
  { name: 'Discord', url: 'https://discord.gg/W2dSA3CHJq', imgSrc: IconDiscord },
  { name: 'YouTube', url: 'https://www.youtube.com/@dmdmusicofficial', imgSrc: IconYouTube },
  { name: 'Facebook', url: 'https://www.facebook.com/DMDMusicOfficial/', imgSrc: IconFacebook },
  { name: 'Instagram', url: 'https://www.instagram.com/dmdmusicofficial/', imgSrc: IconInstagram },
  { name: 'TikTok', url: 'https://www.tiktok.com/@dmdmusicofficial', imgSrc: IconTikTok },
  { name: 'Telegram', url: 'https://t.me/dmdmusicofficial', imgSrc: IconTelegram },
  { name: 'Twitter', url: 'https://twitter.com/DMDMusicoff', imgSrc: IconTwitter },
  { name: 'VK', url: 'https://vk.com/dmdmusicofficial', imgSrc: IconVK },
  { name: 'LinkedIn', url: 'https://www.linkedin.com/company/dmdmusicofficial', imgSrc: IconLinkedIn },
  // ...add the rest of the social networks here
];

const Links = () => {
  return (
    <div className="links-container">
        <a href="/" rel="noopener noreferrer">
            <img
                style={{ width: 300 }}
                className="logo"
                src={Logo}
                alt="logo"
            />
        </a>
        {socialLinks.map((link, index) => (
            <a href={link.url} key={index} target="_blank" rel="noopener noreferrer" className="social-link">
            <div className="social-link-block">
                <span>{link.name}</span>
                <img src={link.imgSrc} alt={`${link.name} neon icon`} />
            </div>
            </a>
        ))}
    </div>
  );
};

export default Links;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

// Получаем текущий язык пользователя
const userLanguage = navigator.language || navigator.userLanguage;

// Устанавливаем язык пользователя
i18n.changeLanguage(userLanguage)
  .then(() => {
    console.log('Language set to', userLanguage);
  })
  .catch((error) => {
    console.error('Failed to set language:', error);
  });

export default i18n;

import React from 'react';
import './css/Links.css';
import { TextPrivacy } from "./TextPrivacy";


const Links = () => {
  return (
    <div className="links-container">
      <TextPrivacy />
    </div>
  );
};

export default Links;
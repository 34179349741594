import './NavBar.css';
import './NavBar.scss'
import React, {useState, useEffect} from 'react';
import { IoClose } from "react-icons/io5";
import ModalWindow from '../HomeSection/components/ModalWindow';
import { useTranslation } from 'react-i18next';

import Logo from "./images/LogoDMD.webp";
import USflag from "./images/flags/us.png";
import RUflag from "./images/flags/ru.png";
import CNflag from "./images/flags/cn.png";

export const NavBar = () => {
    const [showNav, setShowNav] = useState(false);

    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    useEffect(() => {
      i18n.on('languageChanged', (lng) => {
        setSelectedLanguage(lng);
      });
  
      return () => {
        i18n.off('languageChanged');
      };
    }, [i18n]);

    const getFlagByLanguage = (language) => {
        switch (language) {
          case 'en':
            return USflag;
          case 'ru':
            return RUflag;
          case 'zh':
            return CNflag;
          default:
            return USflag;
        }
    };
  
    const changeLanguage = (event, newLanguage) => {
        event.stopPropagation();
        setSelectedLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    return (
        <header>
            <img className="logo" src={Logo} alt="title" />

            <nav className={"nav-menu hidden"}>
                <div className='menu__links'>
                    <a href="#aboutus" className='nav-menu-link' >{t('NavBarAbout')}</a>
                    <a href="#maps" className='nav-menu-link' >{t('Roadmap')}</a>
                    <a href="#community" className='nav-menu-link' >{t('NavBarCommunity')}</a>
                </div>
                <div class="menu__contact">
                    <ModalWindow />
                </div>
                <div className="language-selector">
                    <div className="selected-language">
                        <img src={getFlagByLanguage(selectedLanguage)} alt={selectedLanguage} />
                    </div>
                    <div className="language-options">
                        <div className="option" onClick={(event) => changeLanguage(event, 'en')}>
                            <img src={USflag} alt="en" />
                            <span>English</span>
                        </div>
                        <div className="option" onClick={(event) => changeLanguage(event, 'ru')}>
                            <img src={RUflag} alt="ru" />
                            <span>Русский</span>
                        </div>
                        <div className="option" onClick={(event) => changeLanguage(event, 'zh')}>
                            <img src={CNflag} alt="zh" />
                            <span>中文</span>
                        </div>
                    </div>
                </div>
            <div className={!showNav ? "menu__overlay hidden" : "menu__overlay"}></div>
            </nav>
        </header>
    )
}
import "./Section.css"
import React from 'react'
import 'react-vertical-timeline-component/style.min.css';
import { Puff } from 'react-loading-icons'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { useTranslation } from 'react-i18next';

  export const Section_seven = () => {
    const { t } = useTranslation();
    
    const WORK = [
        {
            id: 1,
            status: "complete",
            textPos: "left",
            data: `${t('S6PhaseDateApril')} - 2023`,
            elementTitle: `${t('S6Phase')} 1`,
            elementSubtitle: "(04/2023~04/2023)",
            elementP: [
                `${t('S6card1Phase1')}`, 
                `${t('S6card2Phase1')}`, 
                `${t('S6card3Phase1')}`, 
                `${t('S6card4Phase1')}`, 
                `${t('S6card5Phase1')}`
            ],
        },
        {
            id: 2,
            status: "complete",
            textPos: "right",
            data: `${t('S6PhaseDateMay')} - 2023`,
            elementTitle: `${t('S6Phase')} 2`,
            elementSubtitle: "(04/2023~04/2023)",
            elementP: [
                `${t('S6card1Phase2')}`,
                `${t('S6card2Phase2')}`,
                // `${t('S6card3Phase2')}`,
                // `${t('S6card4Phase2')}`,
                `${t('S6card5Phase2')}`,
            ],
        },
        {
            id: 3,
            status: "complete",
            textPos: "left",
            data: `${t('S6PhaseDateJune')} - 2023`,
            elementTitle: `${t('S6Phase')} 3`,
            elementSubtitle: "(04/2023~05/2023)",
            elementP: [
                `${t('S6card1Phase3')}`,
                `${t('S6card2Phase3')}`,
                `${t('S6card3Phase3')}`,
                `${t('S6card4Phase3')}`,
            ],
        },
        {
            id: 4,
            status: "activity",
            textPos: "right",
            data: `${t('S6PhaseDateJuly')} - 2023`,
            elementTitle: `${t('S6Phase')} 4`,
            elementSubtitle: "(05/2023~10/2023)",
            elementP: [
                `${t('S6card1Phase4')}`,
                `${t('S6card2Phase4')}`,
                `${t('S6card3Phase4')}`,
                `${t('S6card4Phase4')}`,
            ],
        },
    ]

    const listItems = WORK.map((d) => 
        <VerticalTimelineElement key={d.id}
            className="vertical-timeline-element--work"
            textClassName={ `${d.textPos === "left" ? 'left' : 'right'}` }
            iconClassName={`img_logo ${d.status === "process" ? 'process' : d.status === "complete" ? 'complete' : 'activity'}`}
            contentStyle={{ backgroundImage: 'linear-gradient(-135deg, #6732FF, #9557FF 10%, #4215BF)', color: '#fff', borderRadius: "0.7em", bottom: "15px"}}
            contentArrowStyle={{ borderRight: '0 solid  rgb(33, 150, 243)' }}
            date={d.data}
            dateClassName={`${d.textPos === "right" ? 'left' : 'right'}`}
            iconStyle={{color: '#fff', width: "30px", height: "30px" }}
            
            >
                <h3 className="vertical-timeline-element-title">{d.elementTitle}</h3>
                <h4 className="vertical-timeline-element-subtitle">{d.elementSubtitle}</h4>
                <p style={{ whiteSpace: "pre-line" }}>
                    {d.elementP.map((number) => (
                        <li key={number} style={{ textDecoration: `${d.status === "complete" ? 'line-through' : ''}` }}>
                            {number}
                        </li>
                    ))}
                </p>
        </VerticalTimelineElement>
        );
        // <li key={d.data}>{d.elementTitle}</li>
    return (
        <section id="maps">
            <div className="s6 s6-fon">
                <h2 className="h2 pb5">{t('Roadmap')}</h2>
                <VerticalTimeline>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        textClassName={ 'right' }
                        contentStyle={{ backgroundImage: 'linear-gradient(-135deg, #6732FF, #9557FF 10%, #4215BF)', color: '#fff', borderRadius: "0.7em", bottom: "15px"}}
                        contentArrowStyle={{ borderRight: '0 solid  rgb(33, 150, 243)' }}
                        date={`${t('S6PhaseDateMarch')} - 2023`}
                        iconClassName = {"img_logo complete"}
                        dateClassName={'left'}
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', width: "30px", height: "30px" }}
                    >
                        <h3 className="vertical-timeline-element-title">{t('S6Phase')} 0</h3>
                        <h4 className="vertical-timeline-element-subtitle">(03/2023~03/2023)</h4>
                        <p style={{ textDecoration: "line-through" }}>
                            <li>{t('S6card1Phase0')}</li>
                            <li>{t('S6card2Phase0')}</li>
                            <li>{t('S6card3Phase0')}</li>
                        </p>
                    </VerticalTimelineElement>
                    {/* <Puff /> */}
                    {listItems}
                </VerticalTimeline>
            </div>
        </section>
    )
  }

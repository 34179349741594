import React from 'react';
import Logo from '../components/NavBar/images/LogoDMD.webp'
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: '#fff',
  };

  return (
    <div style={containerStyle}>
      <a href="/" rel="noopener noreferrer">
        <img style={{width: 300}} className="logo" src={Logo} alt="logo" />
      </a>
      <h1>404: Page Not Found</h1>
      <p className='pb3'>Oops! The page you're looking for doesn't exist.</p>
      <button className="start-btn" onClick={goBack}>Go Back</button>
    </div>
  );
};

export default NotFound;
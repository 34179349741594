import React, { useState } from 'react';
import Modal from 'react-modal';

import IconTwitter from "../images/icons/Twitter.svg";
import IconYouTube from "../images/icons/YouTube.svg";
import IconFacebook from "../images/icons/Facebook.svg";
import IconInstagram from "../images/icons/Instagram.svg";
import IconLinkedIn from "../images/icons/LinkedIn.svg";
import IconTelegram from "../images/icons/Telegram.svg";
import IconVK from "../images/icons/VK.svg";
import IconTikTok from "../images/icons/TikTok.svg";
import IconDiscord from "../images/icons/Discord.svg";
import { useTranslation } from 'react-i18next';

export default function ModalWindow() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { t } = useTranslation();


  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const customStyles = {
    content: {
      position: 'absolute',
      borderRadius: '14px',
      top: '50%',
      left: '50%',
      color: 'black',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: 'auto',
      maxWidth: '500px',
      maxHeight: '350px',
      zIndex: 999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 999,
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      padding: '0',
      backgroundColor: 'transparent',
      color: '#000',
      border: 'none',
      cursor: 'pointer',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      lineHeight: '1.5rem',
      textAlign: 'center',
    },
  };

  return (
    <div>
      <button className='start-btn' onClick={openModal}>{t('ButtonDownload')}</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        closeTimeoutMS={200}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <button
          className="ReactModal__Close"
          style={customStyles.closeButton} // Apply the closeButton styles
          onClick={closeModal}
          aria-label="Close"
        >
          X
        </button>
        <div>
          <h2 className='donwload-h2'>{t('S1ModalDownload')}</h2>
          <div>
            <a href='https://play.google.com/store/apps/details?id=com.dmdmusic&utm_source=https%3A%2F%2Fdmdmusic.org%2F&utm_campaign=DMD%20inc.&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              <img
                alt={t('S1ModalDownload')}
                src='https://play.google.com/intl/en_us/badges/static/images/badges/ru_badge_web_generic.png'
                style={{ width: "200px", height: "auto" }}
              />
            </a>
          </div>
          <h2 className='donwload-h2'>{t('S1Modal')}</h2>
          <div className='donwload-Social'>
            <a href='https://twitter.com/DMDMusicoff' target="_blank"><img src={IconTwitter} alt="Twitter" /></a>
            <a href='https://www.youtube.com/@dmdmusicofficial' target="_blank"><img src={IconYouTube} alt="YouTube" /></a>
            <a href='https://www.facebook.com/DMDMusicOfficial/' target="_blank"><img src={IconFacebook} alt="Facebook" /></a>
            <a href='https://www.instagram.com/dmdmusicofficial/' target="_blank"><img src={IconInstagram} alt="Instagram" /></a>
            <a href='https://www.linkedin.com/company/dmdmusicofficial' target="_blank"><img src={IconLinkedIn} alt="LinkedIn" /></a>
            <a href='https://t.me/dmdmusicofficial' target="_blank"><img src={IconTelegram} alt="Telegram" /></a>
            <a href='https://vk.com/dmdmusicofficial' target="_blank"><img src={IconVK} alt="VK" /></a>
            <a href='https://www.tiktok.com/@dmdmusicofficial' target="_blank"><img src={IconTikTok} alt="TikTok" /></a>
            <a href='https://discord.com/invite/W2dSA3CHJq' target="_blank"><img src={IconDiscord} alt="TikTok" /></a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import LoaderScreen from "./components/LoaderScreen.js"
import { Header } from "./components/Header";
import NotFound from './Page/NotFound';
import { Home } from "./components/Home";
import Links from './Page/Links';
import Privacy from './Page/Privacy';
import React, { useState, useEffect } from 'react';

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  // Imitate a delay of 3 seconds before setting the content as loaded.
  // In a real-world scenario, this could be replaced with an API call or another async operation.
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 2000);
  }, []);

  if (!isLoaded) {
    return <LoaderScreen />
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="link" element={<Links />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
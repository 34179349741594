// components/LoaderScreen.js

import React from 'react';
import logo from '../Assets/images/logo_no_text.svg'; // Replace with the correct path to your logo
import './LoaderScreen.css';

function LoaderScreen() {
  return (
      <div className="loader-container">
          <img src={logo} alt="Loading Logo" className="loader-logo" />
      </div>
  );
}

export default LoaderScreen;

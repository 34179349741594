export const TextPrivacy = () => {
    return (
      <section>
        <div></div>
        <h1 style={{color: '#fff'}}>Terms of Use:</h1>
        <p>Welcome to DMD Music! These Terms of Use govern your use of our music streaming service and website. By accessing or using our service, you agree to comply with these terms and any additional guidelines or rules we provide.</p>
        <br />
        <p>Account Registration: You may need to create an account to access certain features of our service. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
        <br />
        <p>Service Usage: Our service is for personal, non-commercial use. You may stream music, create playlists, and interact with other users within the bounds of our service. You agree not to use our service for any illegal, harmful, or unauthorized purposes.</p>
        <br />
        <p>Intellectual Property: All content available on our service, including music, logos, and trademarks, is protected by intellectual property laws. You may not copy, distribute, modify, or create derivative works of the content without our permission.</p>
        <br />
        <p>User Content: You may have the opportunity to submit content to our service, such as comments or playlists. By submitting content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and distribute the content in connection with our service.</p>
        <br />
        <p>Privacy: We respect your privacy and handle your personal information in accordance with our Privacy Policy. Please review the policy to understand how we collect, use, and disclose your information.</p>
        <br />
        <p>Termination: We may terminate or suspend your access to our service at any time, without prior notice, if you violate these Terms of Use or engage in any conduct that we deem harmful to our service or other users.</p>
        <br />
        <p>Disclaimer of Warranties: Our service is provided on an "as is" and "as available" basis, without any warranties or guarantees. We do not warrant that the service will be uninterrupted, error-free, or secure.</p>
        <br />
        <p>Limitation of Liability: To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or relating to your use of our service.</p>
        <br />
        <p>Governing Law: These Terms of Use shall be governed by and construed in accordance with the laws of the jurisdiction where DMD Music is based.</p>
        <br />
        <p>Changes to Terms: We reserve the right to modify or update these Terms of Use at any time. Any changes will be effective upon posting the updated terms on our website. Your continued use of our service after the changes indicates your acceptance of the modified terms.</p>
        <br /><br />

        <h1 style={{color: '#fff'}}>Privacy Policy:</h1>
        <p>At DMD Music, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard the information you provide when using our music streaming service and website.</p>
        <br />
        <p>Information Collection: When you sign up for an account with DMD Music, we collect your username, email address, and password. We only collect this information for the purpose of creating and managing your account.</p>
        <br />
        <p>Use of Information: We do not use or share your personal information with third parties. The information you provide is solely used for the purpose of providing you with access to our service and ensuring the security of your account.</p>
        <br />
        <p>Data Security: We take appropriate measures to protect your information from unauthorized access, alteration, or disclosure. We use industry-standard security protocols and encryption to safeguard your data.</p>
        <br />
        <p>Third-Party Links: Our service does not contain links to third-party websites or services. We do not endorse or have any responsibility for the privacy practices or content of any external sites.</p>
        <br />
        <p>Data Retention: We retain your personal information for as long as necessary to provide you with our service. If you decide to delete your account, your personal information will be permanently removed from our systems.</p>
        <br />
        <p>Children's Privacy: Our service is not directed to children under the age of 13. We do not knowingly collect personal information from children. If we discover that we have inadvertently collected information from a child, we will promptly delete it.</p>
        <br />
        <p>Updates to the Privacy Policy: We may update our Privacy Policy from time to time. Any changes will be posted on our website, and the revised policy will be effective immediately upon posting.</p>
        <br />
        <p>Contact Us: If you have any questions or concerns regarding our Privacy Policy or the protection of your personal information, please contact us using the provided contact details.</p>
        <br />
      </section>
    )
  }
import "./Section.css"
import "../main.css"
// import React from 'react'
// import { motion } from "framer-motion";
import React from 'react';

import IconTwitter from "./images/icon-twitter.svg";
import IconYouTube from "./images/icon-youtube.svg";
import ModalWindow from './components/ModalWindow';
import GraphicDecentralizedOrbitals from "./images/screen.webp";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// const Spline = React.lazy(() => import('@splinetool/react-spline'));

function H1TextAnimate() {
  const { t } = useTranslation();
  return (
      <h1>{t('S1content')}</h1>
  );
}

function PTextAnimate() {
  const { t } = useTranslation();
  return (
    <p>
        {t('S1description')}
      </p>
  );
}

function ButtonAnimate() {
  return (
    <ModalWindow />
    );
  }
  
function ButtonLink() {
  const { t } = useTranslation();
  return (
    <div className="pt3">
      <a href="https://dmdmusic.vercel.app/home" target="_blank">
          <p className='start-btn' style={{ fontWeight: 'bold' }}>{t('ButtonLink')}</p>
      </a>
    </div>
  );
}

function BlockAnimate() {
  return (
      <div className="social">
        <div />
        <a href="https://twitter.com/dmdmusicoff">
          <img src={IconTwitter} alt="Twitter" />
        </a>
        <a href="https://www.youtube.com/channel/UC5JB_s7523AoE-_GMTNjirA">
          <img src={IconYouTube} alt="YouTube" />
        </a>
      </div>
  );
}

function DecentralizedOrbitals() {
  return <img className="spline-img" src={GraphicDecentralizedOrbitals} loading="lazy"
  alt="" />;
}

export const Section_one = () => {
  return (
    <section className="elementor-section">
      <div className="elementor-container pt6">
        <div className="elementor-row">
                    <DecentralizedOrbitals />
          {/* <Suspense fallback={<div className="loader"><GridLoader size={60} color={'#FFF'} loading={true}/></div>}>
            <Spline className="spline" id="canvas3d"
                      scene="https://prod.spline.design/7578z082bfirGvfr/scene.splinecode"/>
          </Suspense> */}
          {/* <Spline className="spline" id="canvas3d"
                    scene="https://prod.spline.design/zwOYfDveXGjqsSRd/scene.splinecode"/> */}
          <BlockAnimate />
          <div className='content'>
            <H1TextAnimate />
            <PTextAnimate />
            {/* <ButtonAnimate /> */}
            <ButtonLink />
          </div>
        </div>
      </div>
    </section>
  )
}
